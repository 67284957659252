import $http from '@/utils/axios/axiosUtils';

export function queryUserInfo() {
  return $http({
    url: `/user/curInfo`,
    method: 'get',
  });
}

export function getUserSystem() {
  return $http({
    url: `/user/system`,
    method: 'get',
  });
}

export function getAllUsers(params: any) {
  return $http({
    url: `/user/list`,
    method: 'post',
    data: params,
  });
}

export function addUser(params: any) {
  return $http({
    url: `/admin/user/add`,
    method: 'post',
    data: params,
  });
}

export function editUser(userId: string, params: any) {
  return $http({
    url: `/admin/user/${userId}`,
    method: 'put',
    data: params,
  });
}

export function enableUser(userId: string | number) {
  return $http({
    url: `/admin/user/enable/${userId}`,
    method: 'post',
  });
}

export function disableUser(userId: string | number) {
  return $http({
    url: `/admin/user/disable/${userId}`,
    method: 'put',
  });
}

export function getUserInfo(userId: string) {
  return $http({
    url: `/user/info/${userId}`,
    method: 'get',
  });
}

export function getAdministratorsList(systemId: string) {
  return $http({
    url: `/user/list/${systemId}/admin`,
    method: 'post',
  });
}
// 查询关联的系统
export function getUserRelationSystem(userId: any) {
  return $http({
    url: `/admin/user/system/${userId}`,
    method: 'get',
  });
}
// 关联系统
export function userRelationSystem(params: any, userId: any) {
  return $http({
    url: `/admin/user/system/${userId}`,
    method: 'put',
    data: params,
  });
}
// 签署协议
export function signAgreement() {
  return $http({
    url: '/user/sign/agreement',
    method: 'post',
  });
}
