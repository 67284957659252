
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'App',
    setup() {
      const store = useStore();
      const cachedViews = computed(() => store.getters['tabs/cacheViews']);
      return {
        cachedViews,
      };
    },
  });
