import { computed, defineComponent, unref, watch } from 'vue';
import './index.less';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const store = useStore();
    const route = useRoute();
    const { currentRoute } = useRouter();
    const sidebarState = computed(() => store.getters['app/sidebarState']);
    const sidebarStateFlag = computed(() => store.getters['app/sidebarStateFlag']);
    const permissionRoutes = computed(() => {
      return store.getters['permission/permissionRoutes'];
    });
    const changeSidebarState = () => store.commit('app/setSidebarState', !sidebarState.value);
    function selectMenu(key: any) {
      store.dispatch('tabs/addTab', {
        title: key.meta.title,
        path: key.path,
      });
    }
    // function mouseover() {
    //   if (!sidebarState.value && !sidebarStateFlag.value) {
    //     changeSidebarState();
    //   }
    // }
    // function mouseoverLeave() {
    //   if (sidebarState.value && !sidebarStateFlag.value) {
    //     changeSidebarState();
    //   }
    // }
    watch(
      () => currentRoute.value,
      (val: object) => {
        selectMenu(val);
      },
      { immediate: true }
    );

    const company = computed(() => store.getters['userinfo/company']);
    function renderMenuItem(menuList?: any, index = 1) {
      if (!menuList) return false;
      return menuList.map((menu: any, _index: number) => {
        if (!menu) return null;
        if (menu.meta.hide) return null;
        if (!menu.children || !menu.meta.showChildren) {
          //
          return (
            <el-menu-item index={menu.path} class={menu.meta.sub ? 'subMenuStyle' : ''}>
              {{
                default: () =>
                  menu.meta.icon && (
                    <i class="dot">
                      {' '}
                      <i class={menu.meta.icon} />
                    </i>
                  ),
                title: () => <span class="menu-font">{menu.meta.title}</span>,
              }}
            </el-menu-item>
          );
        }
        return (
          <el-submenu index={`${index}-${_index}`}>
            {{
              title: () => (
                <>
                  {menu.meta.icon && <i class={menu.meta.icon} />}
                  <span class="menu-font">{menu.meta.title}</span>
                </>
              ),
              default: () => renderMenuItem(menu.children, _index),
            }}
          </el-submenu>
        );
      });
    }
    function renderMenu() {
      return (
        <el-menu
          select={selectMenu}
          router
          collapse={!unref(sidebarState)}
          class="outer-menu"
          default-active={route.path}
          // onMouseover={mouseover}
          // onMouseleave={mouseoverLeave}
        >
          <div class="logo">
            <img
              src={
                !unref(sidebarState)
                  ? company?.value?.logo || process.env.VUE_APP_LOGO_URL
                  : company?.value?.brand || process.env.VUE_APP_BRAND_URL
              }
              alt="logo"
            />
          </div>
          {renderMenuItem(unref(permissionRoutes), 0)}
        </el-menu>
      );
    }
    return () => renderMenu();
  },
});
