const router = [
  {
    path: '/imitateUser',
    name: 'imitateUser',
    component: () => import('@/views/imitateUser/imitateUser.vue'),
    meta: {
      title: '模拟用户',
      icon: 'iconfont icon-expand',
      hide: false,
      permissionKey: 'devUser',
      showChildren: true,
      sequence: 1,
    },
  },
];

export default router;
