/* eslint-disable no-shadow */

const state = {
  userName: '',
  isAdmin: false,
  company: {
    brand: '',
    logo: '',
    name: '',
  },
};

const getters = {
  userName: (state: any) => state.userName,
  isAdmin: (state: any) => state.isAdmin,
  company: (state: any) => state.company,
};

const mutations = {
  setUserName(state: any, userName: string) {
    state.userName = userName;
  },
  setIsAdmin(state: any, isAdmin: boolean) {
    state.isAdmin = isAdmin;
  },
  setCompany(state: any, company: any) {
    state.company = company;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
