
  import type { AppRouteRecordRaw } from '@/router/types.d';
  import { defineComponent, unref, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';

  export default defineComponent({
    name: 'Breadcrumb',
    setup() {
      const router = useRouter();
      const { currentRoute } = useRouter();
      const matchedRoutes: any = ref<AppRouteRecordRaw[]>([]);
      function getBreadcrumb() {
        const { matched } = unref(currentRoute);
        const matchedList = matched.filter((item) => item.meta && item.meta.title).splice(1);
        matchedRoutes.value = matchedList.filter((item) => item.meta && item.meta.title);
      }
      watch(
        () => currentRoute.value,
        () => {
          getBreadcrumb();
        },
        { immediate: true }
      );
      const handleCommand = (command: string) => {
        router.push(command);
      };
      return {
        matchedRoutes,
        handleCommand,
      };
    },
  });
