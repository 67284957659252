
  import { defineComponent, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import store from '@/store';
  import Cookies from 'js-cookie';
  import { getDomain } from '../utils/urlHandler';

  export default defineComponent({
    setup() {
      const router = useRouter();

      const domain = getDomain(window.location.href);

      const exit = () => {
        store.commit('app/setPermissions', []);
        store.commit('app/setHasLogin', false);
        Cookies.remove('token', { domain });
        Cookies.remove('userName', { domain });
        Cookies.remove('tenantId', { domain });
        Cookies.remove('tenantName', { domain });
        sessionStorage.removeItem('switchId');
        sessionStorage.removeItem('token');
        router.push('/login');
      };
      const company: any = computed(() => store.getters['userinfo/company']);

      const username = computed(
        () =>
          `${company?.value?.name ? `${company.value?.name} - ` : ''}${
            store.getters['userinfo/userName']
          }`
      );

      const changePasswordClick = () => {
        router.push('/changePassword');
      };

      return {
        username,
        exit,
        changePasswordClick,
      };
    },
  });
