import type { Router } from 'vue-router';
import store from '@/store/index';

export default function createCachedViewGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    // 设置详情缓存，给定meta里面的parentName字段来标识是否进入详情页面
    // todo 如果都是undefiend的情况处理
    if (to.meta.parentName === from.name) {
      store.dispatch('tabs/addCachedView', from.meta.componentName);
    } else {
      store.dispatch('tabs/delCachedView', from.meta.componentName);
    }
    next();
  });
}
