
  import { defineComponent, computed, onMounted, reactive, toRefs } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import Cookies from 'js-cookie';
  import { queryUserInfo, signAgreement } from '@/api/userinfo';
  import { getDomain } from '@/utils/urlHandler';
  import Breadcrumb from './Breadcrumb.vue';
  import Header from './Header.vue';
  import Menu from './Menu';
  import MutilTab from './MutilTab.vue';

  export default defineComponent({
    name: 'Layout',
    components: {
      Menu,
      Header,
      MutilTab,
      Breadcrumb,
    },
    setup() {
      const router = useRouter();
      const store = useStore();
      const state = reactive({
        centerDialogVisible: false,
        checked: false,
        isAgree: false,
      });
      const sidebarState = computed(() => store.getters['app/sidebarState']);
      const sidebarStateFlag = computed(() => store.getters['app/sidebarStateFlag']);
      const cachedViews = computed(() => store.getters['tabs/cacheViews']);
      const changeSidebarState = () => {
        // ev.target.blur();
        store.commit('app/setSidebarStateFlag', !sidebarStateFlag.value);
        store.commit('app/setSidebarState', !sidebarState.value);
        //
      };

      const domain = getDomain(window.location.href);

      async function getUserInfo() {
        const { data } = await queryUserInfo();
        store.commit('userinfo/setUserName', data.userName);
        store.commit('userinfo/setIsAdmin', data.isAdmin);
        store.commit('userinfo/setCompany', {
          brand: data?.brandUrl,
          logo: data?.logoUrl,
          name: data?.label,
        });
        Cookies.set('userName', data.userName, { domain });
        Cookies.set('tenantId', data?.tenantId, { domain });
        Cookies.set('tenantName', data?.tenantName, { domain });
        state.centerDialogVisible = data.root && !data.signed;
      }

      onMounted(() => {
        getUserInfo();
      });
      const handleCancelClick = () => {
        if (!state.isAgree) {
          store.commit('app/setPermissions', []);
          store.commit('app/setHasLogin', false);
          Cookies.remove('token', { domain });
          Cookies.remove('userName', { domain });
          Cookies.remove('tenantId', { domain });
          Cookies.remove('tenantName', { domain });
          router.push('/login');
        }
        state.centerDialogVisible = false;
      };
      const handleCertainClick = async () => {
        await signAgreement();
        state.isAgree = true;
        state.centerDialogVisible = false;
      };

      return {
        ...toRefs(state),
        sidebarState,
        cachedViews,
        sidebarStateFlag,
        changeSidebarState,
        handleCancelClick,
        handleCertainClick,
      };
    },
  });
