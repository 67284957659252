import $http from '@/utils/axios/axiosUtils';

export function getALlSystem(params: any) {
  return $http({
    url: `/system/list`,
    method: 'post',
    data: params,
  });
}
// 获取系统分页
export function getALlSystemPage(params: any) {
  return $http({
    url: `/system/page`,
    method: 'post',
    data: params,
  });
}

export function deleteSystem(id: any) {
  return $http({
    url: `/system/${id}`,
    method: 'delete',
  });
}

export function editSystem(params: any, id: any) {
  return $http({
    url: `/system/${id}`,
    method: 'put',
    data: params,
  });
}

export function addSystem(params: any) {
  return $http({
    url: `/system`,
    method: 'post',
    data: params,
  });
}

export function authorizationSystem(id: string, params: any) {
  return $http({
    url: `/system/${id}/administrators/authorization`,
    method: 'put',
    data: params,
  });
}

export function getSystemUser(id: string) {
  return $http({
    url: `/system/user/${id}`,
    method: 'get',
  });
}

export function getCharacteristicData() {
  return $http({
    url: `/user/characteristic`,
    method: 'get',
  });
}
