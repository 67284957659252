
  import { defineComponent, computed } from 'vue';

  export default defineComponent({
    props: {
      iconClass: {
        required: true,
        type: String,
      },
    },
    setup(props) {
      const iconName = computed(() => `#icon-${props.iconClass}`);
      return {
        iconName,
      };
    },
  });
