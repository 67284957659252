// const router = {
//   path: '/test2',
//   name: 'test2',
//   component: () => import('@/layout/ViewContainer.vue'),
//   meta: {
//     title: '系统管理',
//     icon: 'el-icon-menu',
//     hide: false,
//     permissionKey: 'menu-test',
//     showChildren: true,
//   },
//   children: [
//     {
//       path: '/test2/demo1',
//       name: 'test21',
//       component: () => import('@/views/userManage/userManage.vue'),
//       meta: {
//         title: '示例2-1',
//         hide: false,
//         permissionKey: 'menu-test',
//         showChildren: false,
//         componentName: 'Test2',
//       },
//     },
//     {
//       path: '/test2/demo1/:id/detail',
//       name: 'test21Detail',
//       component: () => import('@/views/userManage/userManage.vue'),
//       meta: {
//         title: '示例2-1详情',
//         hide: true,
//         permissionKey: 'menu-test',
//         showChildren: false,
//         parentName: 'test21',
//       },
//     },
//     {
//       path: '/test2/demo2',
//       name: 'test22',
//       component: () => import('@/views/userManage/userManage.vue'),
//       meta: {
//         title: '电子围栏',
//         hide: false,
//         permissionKey: 'menu-test',
//         showChildren: false,
//       },
//     },
//   ],
// };

// export default router;

const router = [
  {
    path: '/systemManage',
    name: 'systemManage',
    component: () => import('@/views/systemManage/systemManage.vue'),
    meta: {
      title: '系统管理',
      icon: 'iconfont icon-system',
      hide: false,
      permissionKey: 'menu-admin',
      showChildren: false,
    },
  },
  {
    path: '/addUser',
    name: 'addUser',
    component: () => import('@/views/userManage/addUser.vue'),
    meta: {
      title: '添加用户',
      icon: 'el-icon-menu',
      hide: true,
      permissionKey: 'menu-admin',
      showChildren: false,
    },
  },
  {
    path: '/editUser',
    name: 'editUser',
    component: () => import('@/views/userManage/editUser.vue'),
    meta: {
      title: '编辑用户',
      icon: 'el-icon-menu',
      hide: true,
      permissionKey: 'menu-admin',
      showChildren: false,
    },
  },
  {
    path: '/relationSystems',
    name: 'relationSystems',
    component: () => import('@/views/userManage/relationSystems.vue'),
    meta: {
      title: '关联系统',
      icon: 'el-icon-menu',
      hide: true,
      permissionKey: 'menu-admin',
      showChildren: false,
    },
  },
];

export default router;
