/* eslint-disable no-shadow */
import { RouteLocationNormalized } from 'vue-router';

export interface TabView extends Partial<RouteLocationNormalized> {
  title: string;
  path: string;
}

export interface TabViewState {
  tabnavBox: TabView[];
  cachedViews: (string | undefined)[];
}

const state: TabViewState = {
  tabnavBox: [
    {
      title: '首页',
      path: '/homepage',
    },
  ],
  cachedViews: [],
};
const getters = {
  tabnavBox: (state: TabViewState) => state.tabnavBox,
  cachedViews: (state: TabViewState) => state.cachedViews,
};
const mutations = {
  addTab(state: TabViewState, data: any) {
    for (let i = 0; i < state.tabnavBox.length; i += 1) {
      if (state.tabnavBox[i].path === data.path) {
        return false;
      }
    }
    state.tabnavBox.push({
      title: data.title,
      path: data.path,
    });
    return '';
  },
  removeTab(state: TabViewState, arg: any) {
    const index = state.tabnavBox.findIndex((value: any) => {
      return value.path === arg.tabItem.path;
    });
    state.tabnavBox.splice(index, 1);
    if (arg.tabItem.path === arg.fullPath) {
      const tabActive = state.tabnavBox[index] || state.tabnavBox[index - 1];
      arg.router.push(tabActive.path);
    }
  },
  addCachedView(state: TabViewState, viewName: string) {
    if (!viewName) return;
    if (state.cachedViews.includes(viewName)) return;
    state.cachedViews.push(viewName);
  },
  delCachedView(state: TabViewState, viewName: string) {
    const index: number = state.cachedViews.indexOf(viewName);
    if (index > -1) {
      state.cachedViews.splice(index, 1);
    }
  },
};

const actions = {
  addTab({ commit }: any, data: any) {
    commit('addTab', data);
  },
  removeTab({ commit }: any, data: any) {
    commit('removeTab', data);
  },
  addCachedView({ commit }: any, viewName: string) {
    commit('addCachedView', viewName);
  },
  delCachedView({ commit }: any, viewName: string) {
    commit('delCachedView', viewName);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
