
  import { defineComponent, computed, watch, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';

  export default defineComponent({
    setup() {
      const store = useStore();
      const route = useRoute();
      const router = useRouter();
      const tabsValue = ref(null);
      const tabnavBox = computed(() => store.getters['tabs/tabnavBox']);
      watch(
        () => router.currentRoute.value,
        (val: any) => {
          tabsValue.value = val.path;
        },
        { immediate: true }
      );
      const removeTab = (item: any) => {
        console.log(item);
        store.dispatch('tabs/removeTab', { tabItem: item, fullPath: route.fullPath, router });
      };
      return {
        tabnavBox,
        tabsValue,
        removeTab,
      };
    },
  });
