import type { App } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import scrollWaiter from '@/utils/scrollWaiter';
import { configRoutes, basicRoutes } from './modules/index';
import createGuard from './guard/index';

export const asyncRoutes = configRoutes;

const router = createRouter({
  history: createWebHistory(
    process.env.VUE_APP_ROUTER_BASENAME ? `/${process.env.VUE_APP_ROUTER_BASENAME}` : ''
  ),
  routes: basicRoutes,
  scrollBehavior: async (to, from, savedPosition) => {
    await scrollWaiter.wait();
    if (savedPosition) {
      return savedPosition;
    }
    if (to.matched.every((record, i) => from.matched[i] !== record)) {
      return { left: 0, top: 0 };
    }
    return false;
  },
});

export function setupRouter(app: App<Element>) {
  app.use(router);
  createGuard(router);
}

export default router;
