import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/index.vue';

const files = require.context('.', false, /\.ts$/);

let routes: RouteRecordRaw[] = [];

files.keys().forEach((filename: string) => {
  if (filename === './index.ts') return;
  routes = routes.concat(files(filename).default);
});

export const configRoutes = routes;

export const RootRoute = {
  path: '/',
  name: 'Root',
  component: Layout,
  redirect: '/login',
  meta: {
    title: 'Root',
  },
  children: [],
};
const systemRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: 'Login',
    },
  },
  // todo 其他全局路由
  {
    path: '/404',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '404',
    },
  },
  {
    path: '/privacyDeal',
    component: () => import('@/views/privacyDeal/index.vue'),
    meta: {
      title: 'privacyDeal',
    },
  },
  {
    path: '/userDeal',
    component: () => import('@/views/userDeal/index.vue'),
    meta: {
      title: 'userDeal',
    },
  },
  {
    path: '/changePassword',
    component: () => import('@/views/changePassword/index.vue'),
    meta: {
      title: 'changePassword',
    },
  },
];
export const basicRoutes = [RootRoute, ...systemRoutes];
