/* eslint-disable no-shadow */
import { getCharacteristicData } from '@/api/system';

const state = {
  hasLogin: false,
  permissions: [],
  sidebarState: true,
  sidebarStateFlag: true,
};

const getters = {
  hasLogin: (state: any) => state.hasLogin,
  permissions: (state: any) => state.permissions,
  sidebarState: (state: any) => state.sidebarState,
  sidebarStateFlag: (state: any) => state.sidebarStateFlag,
};

const mutations = {
  setHasLogin(state: any, data: boolean) {
    state.hasLogin = data;
  },
  setPermissions(state: any, permissions: string[]) {
    state.permissions = permissions;
  },
  setSidebarState(state: any, data: boolean) {
    state.sidebarState = data;
    console.log(state.sidebarState);
  },
  setSidebarStateFlag(state: any, data: boolean) {
    state.sidebarStateFlag = data;
    console.log(state.sidebarStateFlag);
  },
};

const actions = {
  async getPermissions({ commit }: any) {
    // TODO 更换成真实的fetchuserpermission请求
    const { data } = await getCharacteristicData();
    const permissions = data?.root ? ['menu-normal', 'menu-admin'] : ['menu-normal'];
    if (data?.devUser) {
      permissions.push('devUser');
    }
    // const data: string[] = dataBolean.data ? ['menu-normal', 'menu-admin'] : ['menu-normal'];
    commit('setPermissions', permissions);
    commit('setHasLogin', true);
    return permissions;
  },
  resetPermissions({ commit }: any) {
    commit('setPermissions', []);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
