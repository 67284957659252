const router = [
  {
    path: '/userManage',
    name: 'userManage',
    component: () => import('@/views/userManage/userManage.vue'),
    meta: {
      title: '用户管理',
      icon: 'iconfont icon-user',
      hide: false,
      permissionKey: 'menu-admin',
      showChildren: true,
    },
    // children: [
    //   {
    //     path: '/jobPipeLine/jobPipeLineList',
    //     name: 'jobPipeLineList',
    //     component: () => import('@/views/userManage/userManage.vue'),
    //     meta: {
    //       title: '报名流程',
    //       hide: false,
    //       icon: 'iconfont icon-dot',
    //       permissionKey: 'menu-admin',
    //       showChildren: false,
    //       componentName: 'Test2',
    //       sub: true,
    //     },
    //   },
    //   {
    //     path: '/jobPipeLine/onLineJobList',
    //     name: 'onLineJobList',
    //     component: () => import('@/views/userManage/userManage.vue'),
    //     meta: {
    //       title: '在离职',
    //       hide: false,
    //       icon: 'iconfont icon-dot',
    //       permissionKey: 'menu-admin',
    //       showChildren: false,
    //       componentName: 'Test2',
    //       sub: true,
    //     },
    //   },
    //   {
    //     path: '/jobPipeLine/interview',
    //     name: 'interview',
    //     component: () => import('@/views/userManage/userManage.vue'),
    //     meta: {
    //       title: '面试',
    //       hide: false,
    //       icon: 'iconfont icon-dot',
    //       permissionKey: 'menu-admin',
    //       showChildren: false,
    //       componentName: 'Test2',
    //       sub: true,
    //     },
    //   },
    // ],
  },
  {
    path: '/addUser',
    name: 'addUser',
    component: () => import('@/views/userManage/addUser.vue'),
    meta: {
      title: '添加用户',
      icon: 'el-icon-menu',
      hide: true,
      permissionKey: 'menu-admin',
      showChildren: false,
    },
  },
  {
    path: '/editUser',
    name: 'editUser',
    component: () => import('@/views/userManage/editUser.vue'),
    meta: {
      title: '编辑用户',
      icon: 'el-icon-menu',
      hide: true,
      permissionKey: 'menu-admin',
      showChildren: false,
    },
  },
];

export default router;
