import type { Router } from 'vue-router';
import createPermissionGuard from './permissionGuard';
import createProgressGuard from './progressGuard';
import createCachedViewGuard from './cachedViewGuard';

function setTitle(title: string, appTitle?: string) {
  const curTitle = title ? `${appTitle}-${title}` : `${appTitle}`;
  document.title = curTitle;
}

export default function createGuard(router: Router) {
  router.beforeEach(async (to, next) => {
    // TODO
  });

  router.afterEach((to: any) => {
    setTitle(to.meta.title, '慕慕工主');
  });
  createProgressGuard(router);
  createPermissionGuard(router);
  createCachedViewGuard(router);
}
